import request from '@/api/utils';
import chartOptions from '../chart/chart_options';
import helper from '../helper';
import common from '../component/common.js';

const getSantralForecastChart = (formdata, measureList) => new Promise((resolve) => {
  const url = '/panel/forecast/hourly';
  const santralData = request.Api(url, formdata, 'post');
  santralData.then((rest) => {
    console.log(rest);
    const _rawData = rest.data.data;
     const table = rest.data.data;
    console.log(_rawData);
    const sortType = 'Hour';
    if (sortType === undefined || Object.keys(_rawData).length === 0) {
      const result = {
        options: {},
        desserts: [],
      };
      resolve(result);
      return;
    }

    let max_val = 0;
    const res = _rawData.sort((a, b) => {
      if (a[sortType] < b[sortType]) return -1;
      if (a[sortType] > b[sortType]) return 1;
      return 0;
    });
    console.log(measureList)
    const results = {};
    res.forEach((item) => {
      if (!results.hasOwnProperty(item.Hour)) {
        results[item.Hour] = {};
      }

      console.log(item)

      measureList.forEach((measure) => {
        console.log(measure)

        if (!results[item.Hour].hasOwnProperty(measure.value)) {
          results[item.Hour][measure.value] = 0;
        }

        results[item.Hour][measure.value] += parseFloat(item[measure.value]);
        console.log(parseFloat(item[measure.value]));
        if (parseInt(results[item.Hour][measure.value]) > max_val) {
          max_val = parseInt(results[item.Hour][measure.value]);
        }
      });
    });
    console.log(results)
    console.log(max_val)
    // let colors = ['#5470C6', '#91CC75', '#EE6666'];
    const colors = common.colors();
    const dateList = [];
    const series = [];
    const measures = [];
    const desserts = [];
    const result = {};
    const yAxis = [];
    let offset = 0;
    let colorKey = 0;

    measureList.forEach((measure) => {
      measures.push(`${measure.text}`);
      const tempData = [];
     

      Object.keys(results).forEach((time) => {
        if (colorKey === 0) {
          dateList.push(time);
        }
        tempData.push(results[time][measure.value]);
      });
      console.log(tempData)

      const chartType = 'line';

      const obj = {
        name: `${measure.text}`,
        data: tempData,
        type: chartType,
      };

      const position = 'left';

      if (colorKey > 1) {
        offset += 110;
      }

      const max = 1200;

      if (colorKey < 1) {
        yAxis.push({
          type: 'value',
          name: measure.text,
          min: 0,
          max,
          offset,
          position,
          show: true,
          axisLabel: {
            formatter: '{value} ',
            show: true,
          },
        });
      }
      console.log(measures)
      series.push(obj);
      colorKey += 1;
    });

    console.log(dateList)
    console.log(series)

    const options = chartOptions.line_options_multies('text', measures, dateList, series,
      colors, yAxis);

    result.options = options;
    result.table = table;

    resolve(result);
  });
});

export default {
  getSantralForecastChart,
};
