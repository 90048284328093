<template>
  <div>
    <v-container class="container--fluid">
      <v-row dense>
        <v-col cols="12">
          <v-card>
            <v-col
              cols="12"
              class="pl-0 pr-0 pb-1"
            >
              <v-row>
                <v-spacer />

                <v-col
                  cols="3"
                  sm="2"
                  md="2"
                  class="pr-3"
                >
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="startDate"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{on, attrs}">
                      <v-text-field
                        v-model="startDate"
                        label="Tarih"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="startDate"
                      no-title
                      scrollable
                    >
                      <v-spacer />
                      <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(startDate)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col
                  cols="3"
                  sm="1"
                  md="1"
                  class="pr-3 pt-3"
                >
                  <v-btn
                    color="primary"
                    @click="getSantralDataChart"
                  >
                    Filtrele
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              :key="componentKey"
              cols="12"
            >
              <echart
                id="general-power-daily"
                class-name="widget-content"
                height="300px"
                width="100%"
                :chart-data="chartData"
              />
            </v-col>
          </v-card>
        </v-col>
      </v-row>

      <v-row dense>
        <v-col
          cols="11"
          class="mb-15 mt-5 ml-2 mr-5"
        >
          <v-col
            cols="12"
            class="pl-0 pr-0 pb-1"
          >
            <v-row>
              <v-spacer />
              <v-tooltip bottom>
                <template v-slot:activator="{on}">
                  <v-btn
                    fab
                    dark
                    small
                    color="cyan"
                    v-on="on"
                    @click="exportDocument"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>Export to Excel</span>
              </v-tooltip>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            class="fullgridtable pl-0 pr-0 pb-1"
          >
            <v-card>
              <v-data-table
                :id="santralDataTable"
                dense
                :items-per-page="itemsPerPage"
                :headers="headers"
                :items="desserts"
                class="elevation-1"
              />
            </v-card>
          </v-col>
        </v-col>
      </v-row>
    </v-container>
    <ExportDocument :export-document-open="exportDocumentOpen" />
  </div>
</template>
<script>
import * as SantralForecast from '@/api/Forecast/SantralForecast';
import Echart from '@/views/components/Echart/Chart.vue';
import moment from 'moment';
import ExportDocument from '@/views/components/Shared/ExportDocument.vue';

export default {
  name: 'GeneralStatsHour',
  components: {
    Echart,
    ExportDocument,
  },
  data: () => ({
    chartData: {},
    startDate: moment().format('YYYY-MM-DD'),
    finishDate: moment().format('YYYY-MM-DD'),
    menu: false,
    modal: false,
    menu2: false,
    measuresModel: ['DailyEnergy'],
    itemsPerPage: 500,
    yearModel: parseInt(moment().format('YYYY')),
    monthModel: parseInt(moment().format('MM')),
    measures: [],
    chartType: ['line', 'bar', 'line2'],
    chartTypeModel: 'line',
    componentKey: 1,
    headers: [],
    desserts: [],
    reportTypeModel: 'Daily',
    reportType: [],
    weekListModel: 10,
    weekList: [],
    exportDocumentOpen: {
      open: false,
      tableID: null,
    },
    santralDataTable: '',
    chartText: '',
    newData: 'true',
    santralModel: [],
  }),
  computed: {
  },
  watch: {},
  created() {},
  mounted() {
    const self = this;

    setTimeout(() => {
      self.getSantralDataChart();
    }, 4500);
  },
  methods: {
    exportDocument() {
      this.exportDocumentOpen.open = true;
      this.exportDocumentOpen.tableID = this.santralDataTable;
    },
    getSantralDataChart() {
      this.chartData = [];
      const self = this;

      const formdata = {
        startDate: this.startDate,
        finishDate: this.startDate,

      };
      const measureList = [
        { value: 'IstantaneousPower', text: 'uretim' },
        { value: 'ForecastIstantaneousPower', text: 'tahmin' },
      ];

      this.headers=[
            { value: 'Time', text: 'Tarih' },
            { value: 'Hour', text: 'Saat' },
            { value: 'santral', text: 'santral' },
            { value: 'IstantaneousPower', text: 'uretim' },
            { value: 'ForecastIstantaneousPower', text: 'tahmin' },
      ];

      

      const tempData = SantralForecast.default.getSantralForecastChart(
        formdata, measureList,

      );
      tempData.then((res) => {
        console.log(res);
        self.chartData = res.options;
        self.desserts = res.table;
      });
    },

  },
};
</script>

<style scoped>
.theme--dark
  .fullgridtable
  .theme--light.v-data-table
  tbody
  tr:not(:last-child) {
  border-bottom: none;
}

.fullgridtable .v-data-table th {
  border: thin solid #0000001f;
}

.fullgridtable .v-data-table td {
  border: thin solid #0000001f;
}

.fullgridtable.theme--dark .v-data-table th,
.fullgridtable.theme--dark .v-data-table td {
  border: thin solid hsla(0, 0%, 100%, 0.12);
}

.fullgridtable .theme--light.v-data-table tbody tr:not(:last-child) {
  border-bottom: none;
}

.fullgridtable.fullorder .v-data-table th,
.fullgridtable.fullorder .v-data-table td {
  border-bottom: 0 !important;
}

.fullgridtable.fullorder .v-data-table td:not(:last-child),
.fullgridtable.fullorder .v-data-table th:not(:last-child) {
  border-right: 0 !important;
}
</style>
